<script>
export default {
  computed: {
    participantStatLabel() {
      if (!this.campaign || !this.campaign.resources) {
        return 0;
      }
      return this.campaign.resources.reduce(
        (sum, a) => sum + (a.fulfillments ? a.fulfillments.length : 0),
        0 //eslint-disable-line
      );
    },
    progressPercent() {
      let quantity = 0;
      let fulfilled = 0;
      this.campaign.resources.forEach((resource) => {
        quantity += resource.quantity;
        fulfilled += this.statForResource(resource);
      });
      return Math.round((fulfilled / quantity) * 100);
    },
  },
  methods: {
    statForResource(resource) {
      let fulfilled = 0;
      if (resource.fulfillments && resource.fulfillments.length > 0) {
        /* eslint-disable */
        const filtered = resource.fulfillments
          .map((x) => x.resources)
          .flat()
          .filter((x) => x.resourceId == resource._id);
        fulfilled = filtered.reduce((a, b) => a + b.quantity, 0);
        /* eslint-enable */
      }
      return fulfilled;
    },
    resourceStatLabel(resource) {
      const { quantity } = resource;
      const fulfilled = this.statForResource(resource);
      return `${fulfilled}/${quantity}`;
    },
  },
};
</script>
